/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~font-awesome/css/font-awesome.css';

* {
  font-family: 'Roboto', sans-serif;
}

.margin-zero-auto {
  margin: 0 auto;
}

.description-text {
  font-weight: 100;
  font-size: 16px;
  line-height: 36px;
}

h1 {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

h2 {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

h3 {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

ul {
  list-style-position: inside;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow-x: hidden;
}

